import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import '../styles/normalize.css'
import "../styles/header.css";
import TradeGothicBoldFont from "../../static/TradeGothicLTStd-Bd2.woff"
import TradeGothicFont from "../../static/TradeGothicLTStd.woff"
import favicon from "../../static/favicon.ico";

const Layout = ({ children }) => {

	const faviconLinks = [
		{ rel: "icon", type: "image/x-icon", href: favicon },
		{ rel: "shortcut icon", type: "image/x-icon", href: favicon },
	];
	
  return <>
		<Helmet
			htmlAttributes={{
				lang: "en",
			}}
			link={faviconLinks}
		>
			<title>Urgent Care in Southern California | Dignity Health</title>
			<meta name="description" content="Submit your location and phone number, and we’ll send your nearest urgent care center's contact card to store in your phone, so our team is just a tap away." />
			<link rel="preload"
				as="font"
				href={TradeGothicBoldFont}
				type="font/woff"
				crossOrigin="anonymous" />
			<link rel="preload"
				as="font"
				href={TradeGothicFont}
				type="font/woff"
				crossOrigin="anonymous" />
		</Helmet>
		<div id="parent-container">
			{/* <div id="header">
				<Link to="/" title="Health It Forward, Bakersfield!">
					<GatsbyImage
						image={data.logo.edges[0].node.gatsbyImageData}
						alt="Dignity Health logo" />
				</Link>
			</div> */}
			{children}
		</div>
	</>;
}

Layout.propTypes = {
	children: PropTypes.node.isRequired
};

export default Layout